// Responsive breakpoints managar
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Breakpoints
$breakpoints: (
  'xs': 576px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
);

.nm {
  @include respond-to('md') {
    display: none !important;
  }
}

.nd {
  display: none;
  @include respond-to('sm') {
    display: block !important;
  }
}

.nav-link.active {
  color: #fff !important;
}

.tc-event {
  position: relative;
  margin-bottom: 15px;

  @include respond-to('xs') {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.tc-event-comm {
  .tc-event-list {
    background-color: #fff5cc;
    border: 1px solid #ffe680;

    li {
      border-right: 1px solid #ffe680;
    }
  }
}

.tc-event-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  border-radius: 4px;
  color: #383d41;
  z-index: 0;

  @include respond-to('md') {
    display: block;
  }

  li {
    display: inline-flex;
    padding: 11px 10px;
    border-right: 1px solid #d6d8db;

    @include respond-to('md') {
      display: block;
      border-right: none;
      border-bottom: 1px solid #d6d8db;
    }

    &:last-child {
      border-right: 0;
    }

    &.tc-event-date {
      min-width: 100px;
      @include respond-to('md') {
        min-width: 0;
      }
    }
    &.tc-event-hour {
      min-width: 65px;
      @include respond-to('md') {
        min-width: 0;
      }
    }
    &.tc-event-type {
      min-width: 135px;
      text-transform: capitalize;
      @include respond-to('md') {
        min-width: 0;
      }
    }
    &.tc-event-operator {
      min-width: 130px;
      @include respond-to('md') {
        min-width: 0;
      }
    }
    &.tc-event-implant {
      min-width: 200px;
      @include respond-to('md') {
        min-width: 0;
      }
    }
    &.tc-event-description {
      width: 100%;

      input {
        opacity: 0;
        position: absolute;
      }

      .btn-clone {
        display: none;
      }

      &:hover .btn-clone {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 35px;
        height: 30px;
        border-radius: 3px;
        background-color: #6c757d;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  &.intervent {
    li {
      &.tc-event-description {
        border-right: none;
      }
      &.tc-event-foto {
        border-left: 1px solid #d6d8db;
        margin-left: auto;
        min-width: 60px;
        cursor: pointer;
      }
    }
  }
}

.tc-event-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
  z-index: 10;

  @include respond-to('md') {
    top: 20px;
    right: -7px;
    transform: none;
  }
}

.tc-event-move {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px;

  @include respond-to('xs') {
    transform: none;
    top: -30px;
    left: 0;
  }
}

.tc-filters-bg {
  width: 100%;
  background-color: #d3e9f8;
  @include respond-to('sm') {
    padding: 10px 0;
    margin-bottom: 20px;
  }
}

.tc-filters {
  padding: 30px 10px 15px 10px;
  display: flex;
  width: 100%;

  @include respond-to('sm') {
    flex-wrap: wrap;
  }

  & > div {
    position: relative;
    padding: 0 5px;

    label {
      position: absolute;
      top: -19px;
      left: 10px;
      font-size: 12px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .form-control {
    width: 100%;
  }

  .tc-filter-re-date-from {
    width: 17%;

    @include respond-to('sm') {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  .tc-filter-re-date-from.error,
  .tc-filter-re-date-by.error {
    input {
      border: 1px solid red;
    }
  }
  .tc-filter-re-date-by {
    width: 17%;
    @include respond-to('sm') {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  .tc-filter-re-type {
    width: 20%;
    @include respond-to('sm') {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  .tc-filter-re-implant {
    width: 20%;
    @include respond-to('sm') {
      width: 100%;
      margin-bottom: 35px;
    }
  }
  .tc-filter-re-description {
    width: 26%;
    &.filter-int {
      width: 40%;
    }
    @include respond-to('sm') {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @include respond-to('sm') {
    .tc-filter-re-submit {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
}

.tc-tabs {
  .tc-tabs-list {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin-bottom: 8px;
    margin-top: 15px;
    padding-left: 0;
    font-weight: bold;

    li {
      padding-left: 10px;
    }

    .tc-tab-date {
      min-width: 100px;
    }

    .tc-tab-time {
      min-width: 65px;
    }

    .tc-tab-type {
      min-width: 135px;
    }

    .tc-tab-operator {
      min-width: 130px;
    }

    .tc-tab-implant {
      min-width: 200px;
    }

    .tc-tab-foto {
      margin-left: auto;
      padding-right: 15px;
    }
  }
}

.tc-profile span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: lightgray;
}

.nota-style {
  position: absolute;
  right: 10px;
  top: -7px;
  padding: 0 5px;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
  background-color: #ffc207;
  color: #333;
}

.client-map {
  width: 100%;
  height: 350px;
}

.client-image {
  height: 475px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tc-general-header {
  .tc-general-title {
    padding: 15px;
  }

  .tc-general-body {
    padding: 15px;
  }

  h3,
  h6 {
    margin-bottom: 0;
  }

  iframe {
    width: 100%;
    height: 350px;
  }
}

.tc-general-image {
  height: 475px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tc-general-download {
  padding: 30px;
  text-align: center;
}

.event-images {
  margin: 0 -7.5px;

  .event-image {
    padding: 7.5px;
    width: 33.333%;
    float: left;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.edit-btn {
  display: block;
  width: 25px;
  height: 25px;
  line-height: 26px;
  text-align: center;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  cursor: pointer;
}

.sign-out-link {
  cursor: pointer;

  &:hover {
    color: #fff !important;
  }
}

.settings-list {
  position: relative;

  .enter-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.pointer {
  cursor: pointer;
}

.ended {
  background-color: red;
  color: #fff !important;
  border-radius: 4px;
  padding: 0 5px;
}

.download-doc-note {
  background-color: #28a745;
  color: #fff !important;
  border-radius: 4px;
  padding: 0 5px;
  cursor: pointer;
}

.grid {
  width: 100%;
  margin-bottom: 30px;
}

.grid-item {
  width: 50%;
  padding: 15px;
  float: left;

  @include respond-to('md') {
    width: 100%;
    float: none;
  }
}

.note-manage {
  display: flex;
  align-items: center;

  .note-input {
    width: 17px;
    height: 17px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    margin-right: 7px;

    &.read {
      opacity: 0.6;
      background-color: #027bff;
      border: 1px solid #027bff;
    }
  }
}

#upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.page-item {
  cursor: pointer;

  &.active {
    cursor: default;
  }
}

.fix-position {
  margin-left: -7px;

  &.int {
    margin-left: 0;
  }

  @include respond-to('md') {
    margin-left: 0;
  }
}

.tecno-spinner {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.login-spinner {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.static-spinner {
  position: static;
}

.no-check {
  position: relative;
  right: -25px;
  margin-bottom: 0;
}

.tecno-alert {
  width: auto;
  position: fixed !important;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.add-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 15px;
  left: 15px;
  width: 55px;
  height: 55px;
  background-color: #28a744;
  border: 1px solid #28a744;
  border-radius: 50%;
  color: #fff;
  outline: none !important;

  svg {
    font-size: 18px !important;
  }
}

.unread-note {
  border: 1px solid red !important;
}

.mobile-logo {
  display: none;
  @include respond-to('md') {
    display: block;
    color: #fff;
    margin-bottom: 0 !important;
    text-transform: uppercase;
  }
}

.upload-wrapper {
  display: flex;

  .photos-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .photos-content-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 190px;
    }

    .photo-col {
      width: 25%;
      padding: 0 5px;
      margin-top: 30px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0;
      }
    }

    .photo-close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      background-color: red;
      z-index: 1;
      cursor: pointer;
    }

    .photo-img {
      width: 100%;
      height: 150px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }

    .photo-caption {
      margin-top: 4px;
    }
  }

  .upload-section {
    .preview-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 193px;
      border: 2px dashed rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.02);
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      border-radius: 4px;
      cursor: pointer;

      svg {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 10px;
        font-size: 36px;
      }
    }

    .preview-img {
      width: 100%;
      height: 150px;
      position: relative;
      margin-bottom: 5px;

      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
      }
    }

    .upload-box {
      input {
        margin-bottom: 5px;
      }
    }
  }
}

.photo-implant {
  display: flex;
  flex-wrap: wrap;

  img {
    width: 100%;
    max-width: 300px;
  }

  button {
    margin-top: 10px;
  }
}

.tec-table-container {
  position: relative;
}

.tec-table-wrapper {
  width: 100%;
  border: 2px solid #000;
  overflow-x: scroll;

  .tec-table {
    box-sizing: border-box;
    white-space: nowrap;
    overflow: auto;

    tr {
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    td,
    th {
      text-align: center;
      padding: 7px 10px;
      border: 1px solid rgba(0, 0, 0, 0.5);
    }

    thead th {
      background-color: #52be80;
      color: #fff;
      z-index: 999;

      &:first-child {
        display: inline-block;
        width: 130px;
        left: 0;
        top: auto;
        border-top-width: 1px;
        margin-top: -1px;
      }
    }

    tbody th {
      background-color: #e9f7ef;
      z-index: 998;
      position: absolute;
      width: 133px;
      left: 0;
      top: auto;
      border-top-width: 1px;
      margin-top: -1px;
    }
  }
}

.prodLoading {
  width: 100%;
  height: 510px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relation-box {
  margin-top: 40px;
}

.relation-implant-list {
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.3);

  .r-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);

      &:last-child {
        border-bottom: none;
      }

      .r-implant {
        width: 40%;
        font-size: 18px;
      }

      .r-options {
        width: calc(60% - 30px);
        padding: 15px 30px;
        border-left: 1px solid rgba(0, 0, 0, 0.3);

        &.generated {
          span {
            display: block;
            margin-bottom: 5px;
          }
          button {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.cell-prod {
  position: relative;
  min-width: 105px;

  &:hover {
    .cell-opt {
      display: block;
    }
  }

  .cell-opt {
    position: absolute;
    display: none;

    button {
      width: 30px;
      margin-right: 5px;
    }
  }

  input {
    width: 100%;
    border: transparent;
    background-color: transparent;
  }
}

.card-custom-note {
  & > p {
    margin-bottom: 0;
  }

  ul {
    padding-left: 20px;
  }
}

.DraftEditor-editorContainer {
  height: 130px !important;
}

.ButtonWrap__root___1EO_R,
.DraftEditor-editorContainer {
  z-index: 0 !important;
}

.cards-blue-bar {
  background-color: #d3e9f8;
  min-height: 83px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.card-manage-from {
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.custom-list-group {
  height: 521px;
  overflow: auto;

  .list-implants-search {
    strong {
      display: block;
      line-height: 1;
    }

    span {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.single-card-main-photo {
  height: 320px;
  border-radius: 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.map-point {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #007bff;

  .map-point-content {
    display: none;
    width: 150px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    top: 16px;
    left: -65px;
    z-index: 10;

    strong {
      display: block;
    }
  }

  &:hover .map-point-content {
    display: block;
  }
}

.section-card {
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;

  .edit-card-loading-wrapper {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .section-card-title {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(#000, 0.15);
    border-radius: 5px 5px 0 0;

    & > div {
      display: flex;
      align-items: center;

      button {
        margin-left: 15px;
      }
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .section-card-content {
    padding: 5px 15px;
    border: 1px solid rgba(#000, 0.15);
    border-radius: 0 0 5px 5px;

    .form-row {
      width: 100%;
    }

    textarea {
      height: 38px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        padding: 12px 5px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.2);

        .contact-visual {
          position: relative;
          padding: 6px 0;

          small {
            position: absolute;
            top: -3px;
            color: rgba(0, 0, 0, 0.3);
            font-size: 11px;
          }

          span {
            position: relative;
            top: 7px;
          }
        }

        &.contact-li {
          justify-content: space-between;
        }

        &:last-child {
          border-bottom: none;
        }

        strong {
          flex: 0 0 auto;
          padding-right: 5px;
        }
      }
    }

    .card-photos-wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .card-photo {
        position: relative;
        width: 160px;
        margin-right: 15px;

        .card-photo-thumb {
          height: 180px;
          overflow: hidden;
          border-radius: 5px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .btn {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}

.modal-big-photo {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .modal-big-photo-close {
    position: fixed;
    right: 30px;
    top: 30px;
    color: #fff;
    font-size: 80px;
    line-height: 35px;
    cursor: pointer;
  }

  .modal-big-photo-content {
    height: 100%;
    max-height: 75%;
  }
}
